.default {
    font-size: 2rem;
    line-height: 48px;
    color: white;
}

.white {
    composes: default;
    color: #fff;
    background: none;
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: #fff;
    -webkit-text-fill-color: #fff;
}

.large {
    composes: default;
    font-size: clamp(28px, 10vw, 3.375rem);
    line-height: 3rem;
    letter-spacing: -0.10125rem;
}
