@use 'styles/marketing' as *;

@mixin small-font {
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}


    .row {
        display: flex;
        gap: 3rem;
    }

    .left {
        flex: 1 1 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        img {
            max-width: 20rem;
        }
    }

    .right {
        flex: 1 1 50%;
        display: flex;
        padding: 1.6875rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;

        border-radius: $button-radius;
        border: 3px solid var(--border);
        background: var(--foreground);
    }

    .contactForm {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            color: var(--body-secondary);
            @include small-font;
        }

        input {
            padding: 0.5625rem 0.8125rem;
            margin-bottom: 1rem;
            border-radius: 0.75rem;
            border: 1px solid var(--body, #242c36);
            background: var(--body-secondary, #f0f2f5);
        }

        button {
            width: 100%;
        }

        .error, .success {
            @include small-font;
            margin: 1rem 0;
            color: var(--body-secondary);
        }
    }

@media (max-width: $mobile-breakpoint) {
        .row {
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }

        .envelope {
            display: none;
        }
}
