@use 'styles/marketing' as *;

.preview {
    display: block;
}

.image {
    width: 100%;
}

@media (max-width: $mobile-breakpoint) {
    .image {
        max-width: 60%;
        margin: auto;
    }
}