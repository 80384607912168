$mobile-breakpoint: 768px;
$button-radius: 0.75rem;

$main-font-size: 1.75rem;
$medium-font-size: 1.5rem;
$small-font-size: 1.25rem;
$button-font-size: 1.5rem;

@mixin small-font {
    font-size: 1.5625rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

@mixin gradient-logo {
    padding: 0 0.5rem;
    font-family: MinecraftTen, Poppins;
    font-size: 3rem;
    letter-spacing: -0.14rem;
}
