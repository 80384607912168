@use "styles/colors";
@use "styles/media";
@use "styles/variables";

.footerContainer {
  width: 100%;
  padding: 24px 72px;
  display: flex;
  align-items: center;
  background: colors.$background-primary;

  @include media.mobile {
    height: auto;
    flex-direction: column;
    text-align: center;
  }

  &.hideBackground {
    background: transparent;
  }

  .leftContent {
    flex: 2;
    display: flex;
    justify-content: flex-start;
    gap: 24px;

    @include media.mobile {
      margin-bottom: 16px;
      order: 1;
    }
  }

  .middleContent {
    flex: 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    a { font-size: 1em; }

    @include media.mobile {
      margin-bottom: 24px;
      order: 3;
    }
  }

  .rightContent {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    @include media.mobile {
      margin-bottom: 24px;
      order: 2;
    }
  }

  .links {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    line-height: 24px;
    text-align: center;

    @include media.mobile {
      flex-direction: column;

      .divider {
        display: none;
      }
    }
  }
}
