@use "styles/marketing" as marketing;

$container-breakpoint: 1194px;
$columns-gap: 1.25rem;

.columns {
    width: 100%;
    overflow-x: visible;
    padding: 2rem 0;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: $columns-gap;
    align-self: stretch;

    --body: #fff;
    --main-font-size: calc(#{marketing.$main-font-size} * 0.9);
    --small-font-size: calc(#{marketing.$small-font-size} * 0.9);
    --min-col-width: 265px;
}

.column {
    position: relative;
    flex: 0 0 clamp(var(--min-col-width), 20%, 295px);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    border-radius: 0.75rem;
    // border: 6px solid var(--border);
    color: var(--body);
    background: var(--foreground);
    text-align: center;

    &.highlight {
        margin-top: -1rem;
        background: var(--foreground-secondary);

        button {
            color: var(--body-secondary);
            background: var(--accent);
            border: 3px solid #fff;
        }

        &::after {
            content: "";
            z-index: -1;
            position: absolute;
            top: 0.5rem;
            height: 100%;
            width: 100%;
            box-sizing: content-box;
            border: 6px solid var(--shadow);
            border-radius: 0.75rem;
            background: var(--shadow);
        }
    }
}

.column p {
    margin: 0;
}

.cta {
    width: 100%;

    button {
        width: 100%;
    }
}

.main,
.secondary,
.bold,
.body {
    font-family: "Poppins";
    font-style: normal;
    line-height: normal;
}

.main {
    font-size: var(--main-font-size);
    font-weight: 700;
}

.secondary {
    font-size: var(--main-font-size);
    font-weight: 400;
}

.bold {
    font-size: var(--small-font-size);
    font-weight: 500;
}

.body {
    font-size: var(--small-font-size);
}

.features {
    display: flex;
    padding: 0 1rem;
    margin-top: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
    align-self: stretch;

    border-radius: 0.75rem;
    border: 1px solid var(--border-light);
}

.feature {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    padding: 1rem 0;
}

.divider {
    width: 100%;
    height: 1px;
    background: var(--border-light);
}

.sale {
    position: absolute;
    right: -1.6em;
    top: 2em;
}

// size at which pricing columns take up the full screen width
@media (max-width: $container-breakpoint) and (min-width: marketing.$mobile-breakpoint) {
    .columns {
        --min-col-width: 225px;

        .column {
            padding: 1.8cqw;
            flex: 0 0 clamp(var(--min-col-width), 23%, 295px);
        }

        .feature {
            min-height: 11vw;
        }

        .main,
        .secondary {
            font-size: clamp(16px, 2vw, 20px);
        }

        .bold,
        .body {
            font-size: clamp(14px, 1.5vw, 16px);
        }
    }
}

@media (max-width: $container-breakpoint) {
    .columns {
        overflow-x: auto;
        justify-content: flex-start;
    }
}

@media (max-width: marketing.$mobile-breakpoint) {
    .columns {
        margin-left: 1rem;
        padding-right: 2rem;
    }

    .column {
        position: relative;
        flex: 0 0 clamp(210px, 80%, 350px);
        gap: 1.25rem;
    }

    .features {
        padding: 1.25rem 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .feature {
        min-height: 110px;
        padding: 0.75rem 0;
    }
}
