$button-radius: 0.75rem;

.default {
    position: relative;
    text-align: center;

    .pushable {
        position: relative;
        padding: 0;
        border-radius: $button-radius;
        border: none;
        background: #477A1E;
        cursor: pointer;
        outline-offset: 4px;
        transition: filter 250ms;
    }

    .front {
        display: flex;
        padding: 0.5rem 2.5vw;
        color: var(--main);
        justify-content: center;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
        background: #70B237;
        border-radius: 0.75rem;
        cursor: pointer;

        will-change: transform;
        transform: translateY(-0.4rem);
        transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
    }

    .edge {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: $button-radius;
        background: #477A1E;
    }

    .pushable:hover .front {
        transform: translateY(-0.5rem);
        transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }

    .pushable:active .front {
        transform: translateY(-2px);
        transition: transform 34ms;
    }
}


.large {
    composes: default;
    .front {
        color: #fff;
        padding: 3rem 2rem;
        transform: translateY(-1.5rem);
    }
}




